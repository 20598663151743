import React from 'react';
import Select from 'react-select/async';
import api from './../../api';

function FilterSelect({ value, onChange, router, routerParams = {}, placeholder }) {
    const loadOptions = async (searchText) => {
        if (router) {
            const resp = await api.post(router, {
                text: searchText,
                params: routerParams
            });
            
            if (resp.status === 200) {
                return resp.data.value;
            }
            return [];
        }
    };

    return (
        <Select
            cacheOptions
            value={value}
            onChange={onChange}
            classNamePrefix="react-select"
            placeholder={placeholder}
            noOptionsMessage={() => 'Sem dados'}
            loadOptions={loadOptions}
            defaultOptions
            isClearable
        />
    );
}

export default FilterSelect;
