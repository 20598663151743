import React, { useState, useEffect, useRef } from 'react'
import './style.scss'

import { ContainerModal, Container, Card, Modal, Tab, mensagemSucesso } from './../../../components/Layout'
import { Select, Input, Form, ButtonSave, SelectAsync, Data } from './../../../components/Form'
import { SemRegistro, MoreRegistro, Deletar, Editar, ButtonGenerico, Table } from './../../../components/Page'

import { FiPlusCircle } from 'react-icons/fi'

import { useLayout } from './../../../context/Layout'
import api from './../../../api'
import PageTracker from '../../../components/Page/PageTracker';

function PassaportVistos() {

    const { setLoadFull } = useLayout()
    const [validacao, setValidacao] = useState('')
    const [showModal, setShowModal] = useState(false)

    const [dataEditar, setDataEditar] = useState({})
    const [dataVisto, setDataVisto] = useState([])

    const formRef = useRef(null)

    useEffect(() => {
        setLoadFull(true)
        onBuscaDados()
        setLoadFull(false)
    }, [])

    async function onBuscaDados() {
        const vistos = await api.post('vistos/dados', {})
        console.log(vistos.data.value)
        setDataVisto(vistos.data.value)

        const resp = await api.post('passaportes/edit', {})
        formRef.current.setData(resp.data.value[0])
    }

    function ButtonNovo() {

        function onNovo() {

            setDataEditar({
                interesse: { value: 'S', label: 'Sim' }
            })
            setShowModal(true)
        }

        return (
            <div onClick={onNovo} data-rh={`Novo visto`}>
                <FiPlusCircle />
            </div>
        )
    }

    async function onSave(data) {
        setLoadFull(true)

        const resp = await api.post('passaportes/update', { dados: data })

        if (resp.data?.erro) {
            setLoadFull(false)
            setValidacao(resp.data.erro.message)
            return
        }

        setLoadFull(false)
    }

    async function onSaveModal(data) {
        setLoadFull(true)

        let resp

        if (data?.id)
            resp = await api.put(`vistos/${data.id}`, data)
        else
            resp = await api.post('vistos', data)

        if (resp.data?.erro) {
            setLoadFull(false)
            setValidacao(resp.data.erro.message)
            return
        }

        setShowModal(false)
        onBuscaDados()
        setLoadFull(false)

    }

    async function onEditar(id) {

        setLoadFull(true)

        const resp = await api.get(`vistos/${id}`)

        if (resp.data?.erro) {
            setLoadFull(false)
            setValidacao(resp.data.erro.message)
            return
        }

        setDataEditar(resp.data.value)
        setLoadFull(false)
        setShowModal(true)

    }

    return (
        <>
            <PageTracker pageUrl='/minhas-informacoes/passaport-vistos' />
            <Container
                showTopNavigation={true}
            >

                <Card
                    nome='Passaporte'
                    col='col-xl-4'
                >
                    <Form
                        onSubmit={onSave}
                        setRef={formRef}
                        initialData={{ possui: { value: 'S', label: 'Sim' } }}
                    >
                        <Select name='TemPassaporte_Consultor' col='col-lg-12' titulo='Possuí Passaporte?' options={[
                            { value: 'S', label: 'Sim' },
                            { value: 'N', label: 'Não' }
                        ]} />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Input name='PasPort_Consultor' col='col-lg-6' titulo='Número' placeholder='0' />
                            <Data name='ValPassaporte_Consultor' col='col-lg-6' titulo='Validade' />
                        </div>

                        <div className='col-lg-6 m-top-16'>
                            <ButtonSave name='Salvar' />
                        </div>
                    </Form>
                </Card>

                <Card
                    nome='Vistos'
                    col='col-xl-8'
                    buttons={[<ButtonNovo />]}
                >

                    {
                        dataVisto.length === 0 ? <SemRegistro /> :
                            <>
                                <Table
                                    campos={[
                                        { nome: 'Pais' },
                                        { nome: 'Tipo do Visto' },
                                        { nome: '' },
                                    ]}
                                    trs={() => {
                                        const tabela = dataVisto.map((item) => {

                                            return (
                                                <tr key={item.id}>
                                                    {
                                                        item.dados.map((dado, index) => {

                                                            if (String(dado).indexOf('http') > -1)
                                                                return <td key={index} style={{ width: 40 }} ><img src={dado} alt="avatar" style={{ width: 40, height: 40, borderRadius: 20 }} /></td>
                                                            else
                                                                return <td key={index}>{dado}</td>
                                                        })
                                                    }
                                                    <td className="text-right button-delete-editar">

                                                        {/* <Editar item={item} /> */}
                                                        {/* <Deletar item={item} /> */}

                                                        {/* {
                                                            tableButtons && tableButtons.map((ButtonItem, index) => <ButtonItem key={index} item={item} />)
                                                        } */}

                                                        <Editar item={item} onEditar={() => onEditar(item.id)} />
                                                        <Deletar item={item} onBuscaDados={onBuscaDados} route={'vistos'} />

                                                    </td>
                                                </tr>
                                            )
                                        })

                                        return tabela

                                    }}
                                />
                            </>
                    }

                </Card>

            </Container>

            {
                showModal &&
                <Modal
                    title='Novo Visto'
                    showModal={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={onSaveModal}
                    validacao={{}}
                    data={dataEditar}
                >

                    {/* <SelectAsync name='Cod_Pais' col='col-lg-12' titulo='Pais' /> */}
                    <SelectAsync name='Cod_Pais' col='col-lg-12' titulo='Pais' labelValue='nome_pais' router='vistos/comboPais' />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <SelectAsync name='Cod_TipoVisto' col='col-lg-6' titulo='Tipo do Visto' router='vistos/comboTipoVisto' />
                        <Data name='Val_VistoCid' col='col-lg-6' titulo='Validade' />
                    </div>

                    <Select name='Interesse_VistoCid' col='col-lg-6' titulo='Tem interesse em atuar neste pais?' options={[
                        { value: 'S', label: 'Sim' },
                        { value: 'N', label: 'Não' }
                    ]} />

                </Modal>
            }
        </>
    )
}

export default PassaportVistos