// src/components/Form/FilterDate.js
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { registerLocale } from "react-datepicker";
import ptbr from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-BR', ptbr);

function FilterDate({ startDate, endDate, onChangeRange, placeholder, groupClass = '' }) {
    const customStyles = {
        datePickerContainer: {
            height: '38px',
            position: 'relative',
            display: 'flex',
            gap: '10px'
        },
        datePickerInput: {
            height: '38px',
            width: '100%',
            padding: '0.625rem 0.75rem',
            fontSize: '0.875rem',
            borderRadius: '0.375rem',
            border: '1px solid #dee2e6',
            backgroundColor: '#fff',
            color: '#8898aa'
        }
    };

    const handleStartDateChange = (date) => {
        onChangeRange(date, endDate);
    };

    const handleEndDateChange = (date) => {
        onChangeRange(startDate, date);
    };

    return (
        <div className="cInputD">
            <div className={`input-group-merge input-group-alternative ${groupClass}`} style={customStyles.datePickerContainer}>
                <ReactDatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Data inicial"
                    locale="pt-BR"
                    isClearable
                    className="form-control"
                    style={customStyles.datePickerInput}
                    showPopperArrow={false}
                />
                <ReactDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Data final"
                    locale="pt-BR"
                    isClearable
                    className="form-control"
                    style={customStyles.datePickerInput}
                    showPopperArrow={false}
                />
                <style>{`
                    .cInputD .react-datepicker__close-icon {
                        right: -8em;
                        top: -4px;
                    }
                    .cInputD .react-datepicker-wrapper {
                        border: 1px solid #d3dae0;
                        background-color: #fff;
                    }
                `}</style>
            </div>
        </div>
    );
}

export default FilterDate;