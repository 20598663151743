// src/components/Page/News/Title.js
import React from 'react';
import '@fontsource/montserrat';

const Title = ({ color, className, fontSize, children }) => {
    const titleStyle = {
        color: color,
        fontSize: fontSize,
        fontWeight: '900',
        fontFamily: 'Montserrat, sans-serif'
    };
    
    return (
        <span style={titleStyle} className={className}>
            {children}
        </span>
    );
};

export default Title;
