import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import ptBR from "@fullcalendar/core/locales/pt-br";
import moment from 'moment';
import api from '../../api';

const CalendarComponent = forwardRef(({ onEventClick, onDateClick, height = 600 }, ref) => {
    const calendarRef = React.createRef();
    const [eventos, setEventos] = useState([]);

    useEffect(() => {
        const startOfMonth = moment().startOf('month').format('YYYYMMDD');
        onBuscaDados(startOfMonth);
    }, []);

    const onBuscaDados = async (data) => {
        const resp = await api.post('agendas/calendario', { dataInicial: data });
        setEventos(resp.data);
    };

    const handlePreviousMonth = () => {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.prev();
        let dataMesAnterior = moment(calendarApi.getDate().toISOString()).format('YYYYMMDD');
        onBuscaDados(dataMesAnterior);
    };

    const handleNextMonth = () => {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.next();
        let dataMesPosterior = moment(calendarApi.getDate().toISOString()).format('YYYYMMDD');
        onBuscaDados(dataMesPosterior);
    };

    useImperativeHandle(ref, () => ({
        refresh: () => {
            const calendarApi = calendarRef.current.getApi();
            const currentDate = moment(calendarApi.getDate()).format('YYYYMMDD');
            onBuscaDados(currentDate);
        },
    }));

    return (
        <FullCalendar
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            dateClick={onDateClick}
            ref={calendarRef}
            header={{
                left: 'today',
                center: 'previousMonth title nextMonth',
                right: 'dayGridMonth'
            }}
            customButtons={{
                previousMonth: {
                    click: handlePreviousMonth
                },
                nextMonth: {
                    click: handleNextMonth
                }
            }}
            buttonIcons={{
                previousMonth: 'chevron-left',
                nextMonth: 'chevron-right'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={eventos}
            eventClick={onEventClick}
            locale={ptBR}
            contentHeight={height}
        />
    );
});

export default CalendarComponent;