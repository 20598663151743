import React from 'react'

import { Link, NavLink } from 'react-router-dom'

import { FiAward, FiAlertTriangle, FiUsers, FiBriefcase } from 'react-icons/fi'

function TopNavigation() {

    return (
        <>
            <div class="header pb-6">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-12">
                                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-12 col-lg-12 col-12">
                                    <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                                        {/* <li class="breadcrumb-item"><a href="#"><i class="fas fa-home"></i></a>Pessoais</li> */}
                                        {/* <li class="breadcrumb-item active" aria-current="page"><a href="/dadospessoais">Pessoais</a></li> */}
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/dadospessoais'>
                                            <span className="nav-link-text">Pessoais</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/dadosbancarios'>
                                            <span className="nav-link-text">Bancárias</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/experienciaprofissional'>
                                            <span className="nav-link-text">Profissionais</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/academica'>
                                            <span className="nav-link-text">Acadêmicas</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/idioma'>
                                            <span className="nav-link-text">Idiomas</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/curso'>
                                            <span className="nav-link-text">Cursos</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/certificacao'>
                                            <span className="nav-link-text">Certificações</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/certificadoespecifico'>
                                            <span className="nav-link-text">Conhecimentos</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/passaporte'>
                                            <span className="nav-link-text">Passaporte e Vistos</span>
                                        </NavLink>
                                        <NavLink className="nav-link breadcrumb-item" activeClassName='active' to='/resumogeral'>
                                            <span className="nav-link-text">Resumo</span>
                                        </NavLink>

                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='container-fluid' style={{ marginTop: -146 }}>
                <div className="row">
                    <div className="col-xl-2 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-atrasados">
                                            <FiBriefcase />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Pessoais</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-pendente">
                                            <FiAlertTriangle />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Bancárias</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-lancados">
                                            <FiUsers />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Profissionais</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-concluido">
                                            <FiAward />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Acadêmicas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-concluido">
                                            <FiAward />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Idiomas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-concluido">
                                            <FiAward />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Cursos</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default TopNavigation