// src/components/Page/News/Section.js
import React from 'react';
import { ReactComponent as IconNossoVoo } from '../../../assets/img/icon-nosso-voo.svg';
import { ReactComponent as IconCalendar } from '../../../assets/img/icon-calendar.svg';

const Section = ({ backgroundColor, children, showIcon = false }) => {
  const sectionStyle = {
    width: '100%',
    minHeight: '300px',
    padding: '40px 150px',
    backgroundColor: backgroundColor,
    position: 'relative'
  };

  const iconNVWrapperStyle = {
    position: 'absolute',
    left: '42%',
    bottom: 0,
    transform: 'translateX(-50%)',
    zIndex: 1
  };

  const iconCWrapperStyle = {
    position: 'absolute',
    left: '90%',
    top: '10px',
    transform: 'translateX(-50%)',
    zIndex: 1
  };

  return (
    <section style={sectionStyle}>
      {showIcon && (
        <>
          <div style={iconNVWrapperStyle}>
            <IconNossoVoo />
          </div>
          <div style={iconCWrapperStyle}>
            <IconCalendar />
          </div>
        </>
      )}
      {children}
    </section>
  );
};

export default Section;