// src/pages/NewsList/index.js
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';
import PageTracker from '../../components/Page/PageTracker';
import VideoTracker from '../../components/VideoTracker';
import './style.scss'
import { Container } from './../../components/Layout';
import { Section, Title, Line, Link } from './../../components/Page/News/Components';
import api from './../../api';
import { Table } from './../../components/Page';
import CalendarWithEditor from '../../components/CalendarWithEditor';
import AdministrativeContactModal from '../../components/AdministrativeContactModal';

function NewsList() {
    const [videoData, setVideoData] = useState(null);
    const [featuredNews, setFeaturedNews] = useState({ first: null, second: null, third: null });
    const [news, setNews] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const [birthdays, setBirthdays] = useState([]);
    const [knowledgeLibrary, setKnowledgeLibrary] = useState([]);
    const [ourFlighs, setOurFlighs] = useState([]);
    const [eventsAndTrainings, setEventsAndTrainings] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
    const [administrativeContacts, setAdministrativeContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [showContactModal, setShowContactModal] = useState(false);

    const history = useHistory();
    const videoTracker = VideoTracker({ videoData });

    const opts = {
        height: '390',
        width: '100%',
        playerVars: {
        autoplay: 0,
        },
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('pt-BR', { month: 'long' });
    };

    const handlePreviousMonth = () => {
        const newMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        setCurrentMonth(newMonth);
    };

    const handleNextMonth = () => {
        const newMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        setCurrentMonth(newMonth);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.allSettled([
                    api.post('/home-contents/latest-video'),
                    api.post('/home-contents/featured'),
                    api.post('/home-contents/news'),
                    api.post('/home-contents/announcements'),
                    api.post('/homes/birthdays', { month: currentMonth }),
                    api.post('/home-contents/knowledge-library'),
                    api.post('/home-contents/ourFlighs'),
                    api.post('/home-contents/eventsAndTrainings'),
                    api.post('/administrative-contacts/dados'),
                ]);
        
                const [
                    videoResponse,
                    featuredResponse,
                    newsResponse,
                    announcementsResponse,
                    birthdaysResponse,
                    knowledgeLibraryResponse,
                    ourFlighsResponse,
                    eventsAndTrainingsResponse,
                    administrativeContactsResponse
                ] = responses;
        
                if (videoResponse.status === 'fulfilled') setVideoData(videoResponse.value.data);
                if (featuredResponse.status === 'fulfilled') setFeaturedNews(featuredResponse.value.data);
                if (newsResponse.status === 'fulfilled') setNews(newsResponse.value.data);
                if (announcementsResponse.status === 'fulfilled') setAnnouncements(announcementsResponse.value.data);
                if (birthdaysResponse.status === 'fulfilled') setBirthdays(birthdaysResponse.value.data);
                if (knowledgeLibraryResponse.status === 'fulfilled') setKnowledgeLibrary(knowledgeLibraryResponse.value.data);
                if (ourFlighsResponse.status === 'fulfilled') setOurFlighs(ourFlighsResponse.value.data);
                if (eventsAndTrainingsResponse.status === 'fulfilled') setEventsAndTrainings(eventsAndTrainingsResponse.value.data);
                if (administrativeContactsResponse.status === 'fulfilled') setAdministrativeContacts(administrativeContactsResponse.value.data.value);
        
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        fetchData();
    }, [currentMonth]);

    const handleNewsClick = (id) => {
        history.push(`/noticia-detalhe/${id}`);
    };

    // Function to extract YouTube video ID from URL
    const getYouTubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    const stripHtml = (html) => {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    useEffect(() => {
        console.log('news', news);
    }, [news]);

    const handleFileDownload = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    const handleContactClick = async (contactId) => {
        try {
            const response = await api.get(`/administrative-contacts/${contactId}`);
            setSelectedContact(response.data.value);
            setShowContactModal(true);
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
    };

    return (
        <>
            <PageTracker pageUrl="/noticias" />
            <Container classAdd='newsClass' showSearch={false} loginBottom='45px'>
                <Section backgroundColor="#FFFFFF">
                    <Title color="#09324C" fontSize="24px">Destaques Aquila</Title>
                    <Line />
                    <div className="row mb-6">
                        <div className="col-6" style={{marginLeft: '40px'}}>
                            <YouTube
                                videoId={videoData ? getYouTubeId(videoData.link) : ''}
                                opts={opts}
                                className="youtube-player"
                                onStateChange={videoTracker.handleVideoState}
                            />
                        </div>
                        <div className="col-6 d-flex flex-column align-items-center" style={{marginLeft: '-40px'}}>
                            <div className="row mb-3 w-100 justify-content-center">
                                <div className="col-12 featured-news-container d-flex justify-content-center">
                                    {featuredNews.first && (
                                        <div 
                                            className="featured-news-square" 
                                            onClick={() => handleNewsClick(featuredNews.first.id)}
                                        >
                                            <img src={featuredNews.first.img_mini_url} alt={featuredNews.first.title} />
                                        </div>
                                    )}
                                    {featuredNews.second && (
                                        <div 
                                            className="featured-news-square"
                                            onClick={() => handleNewsClick(featuredNews.second.id)}
                                        >
                                            <img src={featuredNews.second.img_mini_url} alt={featuredNews.second.title} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row w-100 justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    {featuredNews.third && (
                                        <div 
                                            className="featured-news-rectangle"
                                            onClick={() => handleNewsClick(featuredNews.third.id)}
                                        >
                                            <img src={featuredNews.third.img_mini_url} alt={featuredNews.third.title} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Title color="#09324C" fontSize="24px">Notícias</Title>
                    <div className="news-grid">
                        {news.map((newsItem) => (
                            <div 
                                key={newsItem.id} 
                                className="news-item"
                                onClick={() => handleNewsClick(newsItem.id)}
                            >
                                <div className="title-container">
                                    <Link color="#09324C" fontWeight="100">{newsItem.title}</Link>
                                </div>
                                <div className="image-container">
                                    <img 
                                        src={newsItem.img_mini_url} 
                                        alt={newsItem.title}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Section>
                <Section backgroundColor="#F1F1F1">
                    <Title color="#09324C" fontSize="24px">Comunicados</Title>
                    <div className="announcements-container">
                        {announcements.map((announcement) => (
                            <div 
                                key={announcement.id} 
                                className="announcement-item"
                                onClick={() => handleNewsClick(announcement.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="title-wrapper">
                                    <div className="triangle-icon" />
                                    <Link color="#09324C" fontWeight="600">
                                        {announcement.title}
                                    </Link>
                                </div>
                                <p>{stripHtml(announcement.text_mini)}</p>
                            </div>
                        ))}
                    </div>
                </Section>
                <Section backgroundColor="#09324C" showIcon="true">
                    <div className="row">
                        <div className="col-6">
                            <Title color="#FFFFFF" fontSize="24px">Nosso Voo</Title>
                            <div className="our-flighs-container">
                                {ourFlighs.map((fligh) => (
                                    <div 
                                        key={fligh.id} 
                                        className="our-flighs-item"
                                        onClick={() => handleNewsClick(fligh.id)}
                                    >
                                        <div className="title-wrapper">
                                            <div className="triangle-icon" />
                                            <Link color="#FFFFFF" fontWeight="600">
                                                {fligh.title}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-6">
                            <Title color="#FFFFFF" fontSize="24px">Eventos e Treinamentos</Title>
                            <div className="our-flighs-container">
                                {eventsAndTrainings.map((event) => (
                                    <div 
                                        key={event.id} 
                                        className="our-flighs-item"
                                        onClick={() => handleNewsClick(event.id)}
                                    >
                                        <div className="title-wrapper">
                                            <div className="triangle-icon" />
                                            <Link color="#FFFFFF" fontWeight="600">
                                                {event.title}
                                            </Link>
                                            <span className="text-mini">| {stripHtml(event.text_mini)}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Section>
                <Section backgroundColor="#FFFFFF">
                    <Title color="#09324C" fontSize="24px">Agenda</Title>
                    <div className="calendar-container mb-6">
                        <CalendarWithEditor height={500} />
                    </div>
                </Section>
                <Section backgroundColor="#F1F1F1">
                <div className="row">
                    <div className="col-6">
                        <div className="title-section">
                            <div className="title-item">
                                <Title color="#09324C" fontSize="24px">Biblioteca do Conhecimento</Title>
                                <div className="knowledge-library-container">
                                    {knowledgeLibrary.map((item) => (
                                        <div 
                                            key={item.id} 
                                            className="knowledge-item"
                                            onClick={() => window.open(item.link, '_blank')}
                                        >
                                            <div className="title-wrapper">
                                                <div className="triangle-icon" />
                                                <Link color="#09324C" fontWeight="600">
                                                    {item.title}
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="title-item">
                                <Title color="#09324C" fontSize="24px">Departamento Pessoal</Title>
                            </div>
                            <div className="title-item">
                                <Title color="#09324C" fontSize="24px">Fale com o Administrativo</Title>
                                <div className="administrative-contacts-container">
                                    {administrativeContacts.map((contact) => (
                                        <div 
                                            key={contact.id} 
                                            className="administrative-contact-item"
                                            onClick={() => handleContactClick(contact.id)}
                                        >
                                            <div className="title-wrapper">
                                                <div className="triangle-icon" />
                                                <Link color="#09324C" fontWeight="600">
                                                    {contact.dados[0]}
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="birthdays-wrapper">
                            <div className="title-container">
                                <Title 
                                    color="#09324C" 
                                    fontSize="18px" 
                                >
                                    Aniversariantes do Mês
                                </Title>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <button onClick={handlePreviousMonth} style={{ border: 'none', background: 'none', color: '#36749B' }}>
                                        {'<<'}
                                    </button>
                                    <Title color="#36749B">{getMonthName(currentMonth)}</Title>
                                    <button onClick={handleNextMonth} style={{ border: 'none', background: 'none', color: '#36749B' }}>
                                        {'>>'}
                                    </button>
                                </div>
                            </div>
                            <Table
                                trs={() => birthdays.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <img src={item.avatar} alt="Avatar" width={40} height={40} style={{ borderRadius: 4 }} />
                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                                    <span style={{ display: 'flex', flex: 1, color: '#09324C', fontSize: '18', fontWeight: 'bold', fontFamily: 'verdana' }}>{item.nome}</span>
                                                    <small></small>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span style={{fontWeight: 'bold'}}>{item.dia}/{item.mes}</span></td>
                                    </tr>
                                ))}
                            />
                        </div>
                    </div>
                </div>
                </Section>
            </Container>
            <AdministrativeContactModal 
                show={showContactModal}
                contact={selectedContact}
                onHide={() => setShowContactModal(false)}
            />
        </>
    );
}

export default NewsList;