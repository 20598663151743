import React, { useState, useEffect, useRef } from 'react'
import './style.scss'

import { ContainerModal, Container, Card, Modal, Tab, mensagemSucesso, mensagemErro } from './../../../components/Layout'
import { Select, Input, Form, ButtonSave, SelectAsync, Textarea } from './../../../components/Form'
import { SemRegistro, MoreRegistro, Deletar, Editar, ButtonGenerico, Table } from './../../../components/Page'

import { FiPlusCircle } from 'react-icons/fi'

import { useLayout } from './../../../context/Layout'
import api from './../../../api'
import PageTracker from '../../../components/Page/PageTracker';

function ResumoGeral() {

    const { setLoadFull } = useLayout()
    const [validacao, setValidacao] = useState('')
    const [codConsultor, setCodConsultor] = useState({})


    const formRef = useRef(null)

    useEffect(() => {
        setLoadFull(true)
        onBuscaDados()
        setLoadFull(false)
    }, [])

    async function onBuscaDados() {
        const resp = await api.post('resumos/edit', {})
        formRef.current.setData(resp.data.value[0])
        setCodConsultor(resp.data.value[0].Cod_Consultor)
    }

    async function onSave(data) {
        setLoadFull(true)

        const resp = await api.post('resumos/update', {
            Cod_Consultor: codConsultor,
            dados: data
        })

        if (resp.data.type !== 0) {
            setLoadFull(false)
            // setValidacao(resp.data.value)
            mensagemErro(resp.data.value)
            return
        }

        setLoadFull(false)
        mensagemSucesso('Resumo atualizado com sucesso!')
    }

    return (
        <>
            <PageTracker pageUrl='/minhas-informacoes/resumo' />
            <Container
                showTopNavigation={true}
                showSearch={false}
            >
                <Card nome='Resumo geral'>

                    <div className='col-lg-12' style={{ marginBottom: 16, fontSize: 12 }}>
                        Escreva resumo geral da sua experiência profissional para ser incluído no Mini do Comercial, no máximo de 500 carácteres.<br />
                        Deverá constar tempo de experiência, metodologias de melhorias e segmentos que atuou:
                    </div>
                    <Form
                        onSubmit={onSave}
                        setRef={formRef}
                    >
                        <Textarea name='Resumo_Exp' col='col-lg-12' titulo='Resumo geral' placeholder='Digite um breve resumo' rows={10} />
                        <div className='col-lg-6 m-top-16'>
                            <ButtonSave name='Salvar' />
                        </div>
                    </Form>

                </Card>
            </Container>
        </>
    )
}

export default ResumoGeral