import React from "react";
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import { useAuth } from './context/Auth'

import Login from './pages/Login'
import EsqueciSenha from './pages/EsqueciSenha'
import Home from './pages/Home'
import Agenda from './pages/Agenda'
import Arquivo from './pages/Arquivo'
import Calendario from './pages/Calendario'
import CalendarExample from './pages/CalendarExample'
import Calendar from './pages/Calendar'
import Catalogo from './pages/Catalogo'
import Relatorio from './pages/Relatorio'
import Simulacao from './pages/Simulacao'
import Projeto from './pages/Projeto'
import ProjetoEquipe from './pages/ProjetoEquipe'
import Contatos from './pages/Contatos'
import PowerBi from './pages/PowerBi'
import Pagamento from './pages/Pagamento'
import PagamentoAplicativo from './pages/PagamentoAplicativo'

import DadosPessoais from './pages/MinhasInformacoes/DadosPessoais'
import CerfificadoEspecifico from './pages/MinhasInformacoes/ConhecimentoEspecifico'
import DadosBancarios from './pages/MinhasInformacoes/DadosBancarios'
import ExperienciaProfissional from './pages/MinhasInformacoes/ExperienciaProfissional'
import Academica from './pages/MinhasInformacoes/Academica'
import Idioma from './pages/MinhasInformacoes/Idioma'
import Curso from './pages/MinhasInformacoes/Curso'
import Certificacao from './pages/MinhasInformacoes/Certificacao'
import PassaportVistos from './pages/MinhasInformacoes/PassaportVistos'
import ResumoGeral from './pages/MinhasInformacoes/ResumoGeral'
import Noticias from "./pages/Noticias";
import NewsList from "./pages/NewsList";
import NoticiaDetalhe from "./pages/NoticiaDetalhe";
import AdministrativeContact from "./pages/AdministrativeContact";

import MinhaSenha from './pages/MinhaSenha'

function Router() {
    return (
        <BrowserRouter>
            <Switch>

                <Route exact path="/" >
                    <Login />
                </Route>

                <Route path="/esquecisenha" >
                    <EsqueciSenha />
                </Route>

                <PrivateRoute path="/home">
                    <Home />
                </PrivateRoute>

                <PrivateRoute path="/agenda">
                    <Agenda />
                </PrivateRoute>

                <PrivateRoute path="/arquivo">
                    <Arquivo />
                </PrivateRoute>

                <PrivateRoute path="/calendario">
                    <Calendario />
                </PrivateRoute>

                <PrivateRoute path="/calendar">
                    <Calendar />
                </PrivateRoute>

                <PrivateRoute path="/calendarexample">
                    <CalendarExample />
                </PrivateRoute>

                <PrivateRoute path="/catalogo">
                    <Catalogo />
                </PrivateRoute>

                <PrivateRoute path="/relatorio">
                    <Relatorio />
                </PrivateRoute>

                <PrivateRoute path="/simulacao">
                    <Simulacao />
                </PrivateRoute>

                <PrivateRoute path="/pagamento">
                    <Pagamento />
                </PrivateRoute>

                <PrivateRoute path="/pagamentoAplicativo">
                    <PagamentoAplicativo />
                </PrivateRoute>

                <PrivateRoute path="/projeto">
                    <Projeto />
                </PrivateRoute>

                <PrivateRoute path="/projetoequipe">
                    <ProjetoEquipe />
                </PrivateRoute>

                <PrivateRoute path="/contato">
                    <Contatos />
                </PrivateRoute>

                <PrivateRoute path="/dadospessoais">
                    <DadosPessoais />
                </PrivateRoute>

                <PrivateRoute path="/certificadoespecifico">
                    <CerfificadoEspecifico />
                </PrivateRoute>

                <PrivateRoute path="/dadosbancarios">
                    <DadosBancarios />
                </PrivateRoute>

                <PrivateRoute path="/experienciaprofissional">
                    <ExperienciaProfissional />
                </PrivateRoute>

                <PrivateRoute path="/academica">
                    <Academica />
                </PrivateRoute>

                <PrivateRoute path="/idioma">
                    <Idioma />
                </PrivateRoute>

                <PrivateRoute path="/curso">
                    <Curso />
                </PrivateRoute>

                <PrivateRoute path="/certificacao">
                    <Certificacao />
                </PrivateRoute>

                <PrivateRoute path="/passaporte">
                    <PassaportVistos />
                </PrivateRoute>

                <PrivateRoute path="/resumogeral">
                    <ResumoGeral />
                </PrivateRoute>

                <PrivateRoute path="/minhasenha">
                    <MinhaSenha />
                </PrivateRoute>

                <PrivateRoute path="/noticias">
                    <Noticias />
                </PrivateRoute>

                <PrivateRoute path="/noticia-detalhe/:id">
                    <NoticiaDetalhe />
                </PrivateRoute>

                <PrivateRoute path="/newslist">
                    <NewsList />
                </PrivateRoute>

                <PrivateRoute path="/administrative-contacts">
                    <AdministrativeContact />
                </PrivateRoute>

                {/* Restringe acesso a outras rotas */}
                <Route path="*">
                    <Redirect to={{ pathname: "/" }} />
                </Route>

            </Switch>
        </BrowserRouter>
    );
}

function PrivateRoute({ children, ...rest }) {

    const { getUser } = useAuth()

    return (
        <Route
            {...rest}
            render={({ location, props }) =>
                 getUser() ?
                children
                 :
                 <Redirect to={{ pathname: "/", state: { from: location } }} />
            }
        />
    );
}

export default Router
