import React from 'react';

const Line = () => {
    const hrStyle = {
        border: 'none',
        borderTop: '1px dotted #D3D3D3',
        margin: '10px 0'
    };
  
    return (
      <hr style={hrStyle} />
    );
  };
  
  export default Line;