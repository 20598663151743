import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import CalendarComponent from '../Calendar';
import { Modal, mensagemErro } from '../Layout';
import { Select, Input, Form, SelectAsync, Textarea, Data, ValidacaoYup, Checkbox } from '../Form';
import { useLayout } from '../../context/Layout';
import api from '../../api';

const CalendarWithEditor = ({ height }) => {
    const { setLoadFull } = useLayout();
    const [showModal, setShowModal] = useState(false);
    const [showSabado, setShowSabado] = useState(false);
    const [modoEditar, setModoEditar] = useState(false);
    const [dataEditar, setDataEditar] = useState({});
    const [dataProposta, setDataProposta] = useState({});
    const [dataMesAnoBusca, setMesAnoBusca] = useState({});
    const [dataAtuacao, setDataAtuacao] = useState({});
    const [dataMunicipio, setDataMunicipio] = useState({});
    const [dataPrevisto, setDataPrevisto] = useState();
    const [dataAgendado, setDataAgendado] = useState();
    const calendarComponentRef = useRef(null);

    const validaCampos = {
        cod_prop_ativ: ValidacaoYup.string().required(),
        cod_grupo: ValidacaoYup.string().min(1).required(),
        data_inicial: ValidacaoYup.date().typeError('É UM CAMPO OBRIGATÓRIO').required(),
        data_final: ValidacaoYup.date().typeError('É UM CAMPO OBRIGATÓRIO').required(),
        chor_esc: ValidacaoYup.string().required(),
    };

    useEffect(() => {
        const startOfMonth = moment().startOf('month').format('YYYYMMDD')
        setMesAnoBusca(startOfMonth)
        onBuscaDados(startOfMonth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onBuscaDados = async (data) => {
        setLoadFull(true);
        setLoadFull(false);
    };

    const onNovo = (data) => {
        setDataEditar({
            data_inicial: data,
            data_final: data,
            ano_mes: moment(data).format('MM/YYYY')
        });
        setShowModal(true);
        setShowSabado(false);
    };

    const onSaveModal = async (data) => {
        setLoadFull(true);

        let codProp = data.cod_prop_ativ.split(";")[0];

        if (!(codProp === "BLOQUEADO" || codProp === "OCUPADO")) {
            if (data.cod_mun === "") {
                setLoadFull(false);
                mensagemErro('Selecione um Município');
                return;
            }
        }

        let resp;

        if (data?.id)
            resp = await api.post('agendas/update', data);
        else
            resp = await api.post('agendas/save', data);

        if (resp.data.type !== 0) {
            setLoadFull(false);
            mensagemErro(resp.data.value);
            return;
        }

        setShowModal(false);
        setShowSabado(false);
        const dataBusca = moment(data.data_inicial).startOf('month').format('YYYYMMDD');
        onBuscaDados(dataBusca);
        setLoadFull(false);
        if (calendarComponentRef.current) {
            calendarComponentRef.current.refresh();
        }
    };

    const onDeleteModal = async (data) => {
        setLoadFull(true);

        let resp;

        if (data?.id)
            resp = await api.post('agendas/delete', data);

        if (resp.data.type === 1) {
            setLoadFull(false);
            mensagemErro(resp.data.value);
            return;
        }

        setShowModal(false);
        setShowSabado(false);
        const dataBusca = moment(data.data_inicial).startOf('month').format('YYYYMMDD');
        onBuscaDados(dataBusca);
        setLoadFull(false);
        if (calendarComponentRef.current) {
            calendarComponentRef.current.refresh();
        }
    };

    const onBuscaDadosSelects = async (data) => {
        if (data) {
            const atuacao = await api.post('agendas/comboAtuacao', { proposta: data, mes: dataMesAnoBusca });
            setDataAtuacao(atuacao.data.value);

            const municipio = await api.post('agendas/comboMunicipio', { proposta: data });
            setDataMunicipio(municipio.data.value);

            setDataProposta(data);

            const diasPrevistoAgendado = await api.post('agendas/diasPrevistoAgendado', { proposta: data, atuacao: atuacao.data.value[0], mes: dataMesAnoBusca });
           
            if(!diasPrevistoAgendado?.data?.value) return;
            
            const { previstos, agendados } = diasPrevistoAgendado?.data?.value;

            setDataPrevisto(previstos);
            setDataAgendado(agendados);
        }
    };

    const onBuscaDadosDias = async (data) => {
        if (data) {
            const diasPrevistoAgendado = await api.post('agendas/diasPrevistoAgendado', { proposta: dataProposta, atuacao: data, mes: dataMesAnoBusca });
            
            if(!diasPrevistoAgendado?.data?.value) return;
            
            const { previstos, agendados } = diasPrevistoAgendado?.data?.value;

            setDataPrevisto(previstos);
            setDataAgendado(agendados);
        }
    };

    const handleChange = (event) => {
        if (event) {
            setShowSabado(true);
        }
    };

    const handleEventClick = async (clickInfo) => {
        let dataClick = moment(clickInfo.event.start).utc(0).format('YYYYMMDD');
        const resp = await api.post('agendas/edit', {
            parametroData: dataClick,
            parametroCodigo: clickInfo.event.id
        });
        setDataEditar(resp.data.value);
        setModoEditar(true);
        setShowModal(true);
    };

    const handleDateClick = (selectInfo) => {
        setModoEditar(false);
        onNovo(moment(selectInfo.dateStr).utc(0).format('MM/DD/YYYY'));
    };

    return (
        <>
            <CalendarComponent
                ref={calendarComponentRef}
                onEventClick={handleEventClick}
                onDateClick={handleDateClick}
                height={height}
            />

            {showModal && (
                <Modal
                    title='Inserir Compromisso'
                    showModal={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={onSaveModal}
                    onDelete={onDeleteModal}
                    validacao={validaCampos}
                    data={dataEditar}
                    showButtonDelete={true}
                >
                    <div style={{ display: 'flex', flexDirection: 'row' }} hidden>
                        <Input name='ano_mes' col='col-lg-4' titulo='Mês / Ano' disabled />
                        <Input name='desc_Esc' col='col-lg-8' titulo='Desc. Escala' disabled />
                    </div>

                    <SelectAsync
                        name='cod_prop_ativ'
                        col='col-lg-12'
                        titulo='Projeto'
                        labelValue='nome_projeto'
                        router='agendas/comboProjeto'
                        routerParams={{
                            data: dataMesAnoBusca
                        }}
                        onChange={onBuscaDadosSelects}
                        isDisabled={modoEditar}
                    />

                    <Select
                        name='cod_grupo'
                        col='col-lg-12'
                        titulo='Atuação'
                        labelValue='nome_atuacao'
                        options={dataAtuacao}
                        isDisabled={modoEditar}
                        onChange={onBuscaDadosDias}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input name='dias_previstos' col='col-lg-6' titulo='Dias Previstos' placeholder='0' value={dataPrevisto} disabled />
                        <Input name='dias_agendados' col='col-lg-6' titulo='Dias Agendados' placeholder='0' value={dataAgendado} disabled />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <Data name='data_inicial' col='col-lg-6' titulo='Data Inicial' disabled={modoEditar} />
                        <Data name='data_final' col='col-lg-6' onChangeValueData={handleChange} titulo='Data Final' disabled={modoEditar} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <SelectAsync name='chor_esc' col='col-lg-6' titulo='Disponibilidade' labelValue='nome_disponibilidade' router='agendas/comboDisponibilidade' isDisabled={modoEditar} />
                        <Select name='cod_mun' col='col-lg-6' titulo='Município' labelValue='nome_municipio' options={dataMunicipio} />
                    </div>

                    <Textarea name='obs_esc' col='col-lg-12' titulo='Observação' placeholder='Digite a Observação' rows={3} />

                    <div style={{ marginTop: '10px' }}>
                        {
                            showSabado ? <Checkbox name='ignorarSabado' titulo='Cadastrar agenda no sábado' col='col-lg-12' /> : null
                        }
                    </div>
                </Modal>
            )}
        </>
    );
};

export default CalendarWithEditor;