import { useAuth } from '../context/Auth';
import api from '../api';

function VideoTracker({ videoData, onStateChange }) {
  const { getUser } = useAuth();
  const user = getUser();

  const handleVideoState = (event) => {
    const currentUser = user;
    const startTime = Date.now();

    if (event.data === 1) { // Video started playing
      event.target.startTime = startTime;
    } else if (event.data === 0 || event.data === 2) { // Video ended or paused
      const endTime = Date.now();
      const duration = (endTime - (event.target.startTime || startTime)) / 1000;

      api.post('/logs', {
        user: {
          name: currentUser.nome,
          email: currentUser.email,
          ip: currentUser.ip || '',
        },
        pages_visited: [
          {
            page_url: 'video',
            start_time: new Date(event.target.startTime || startTime).toISOString(),
            end_time: new Date(endTime).toISOString(),
            duration_seconds: duration,
            publication_title: videoData?.title || null,
            publication_id: videoData?.id || null,
          },
        ],
      });
    }

    if (onStateChange) {
      onStateChange(event);
    }
  };

  return { handleVideoState };
}

export default VideoTracker;