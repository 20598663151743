import React, { useState, useEffect } from 'react'
import './style.scss'

import { ContainerModal } from './../../../components/Layout'
import { Select, SelectAsync, ValidacaoYup } from './../../../components/Form'

import api from './../../../api'
import PageTracker from '../../../components/Page/PageTracker';

function ConhecimentoEspecifico() {

    const [dataConhecimento, setDataConhecimento] = useState({})

    async function onBuscaDadosSelects(data) {

        if (data) {
            const conhecimento = await api.post('conhecimentoespecificos/comboConhecimentoEspecifico', data)
            console.log(conhecimento)
            setDataConhecimento(conhecimento.data.value)
        }
    }

    return (
        <>
            <PageTracker pageUrl="/informacoes-pessoais/conhecimento-especifico" />
            <ContainerModal
                route='conhecimentoespecificos'
                title='Conhecimentos Específicos'
                showButtonPagination={false}
                validacao={{
                    Cod_AreaConhec: ValidacaoYup.string().min(1).required(),
                    Cod_ConhecEspec: ValidacaoYup.string().min(1).required(),
                    Cod_NivelConhec: ValidacaoYup.string().min(1).required(),
                }}
                showTopNavigation={true}
                campos={[
                    { nome: 'Conhecimento Específico' },
                    { nome: 'Nível de Conhecimento' },
                ]}
            >

                <SelectAsync
                    name='Cod_AreaConhec'
                    col='col-lg-12'
                    titulo='Área de conhecimento'
                    router='conhecimentoespecificos/comboAreaConhecimento'
                    onChange={onBuscaDadosSelects}
                />

                <Select name='Cod_ConhecEspec' col='col-lg-12' titulo='Conhecimento Específico' options={dataConhecimento} />

                <SelectAsync name='Cod_NivelConhec' col='col-lg-12' titulo='Nível de conhecimento' router='conhecimentoespecificos/comboNivelConhecimento' />

            </ContainerModal>
        </>
    )
}

export default ConhecimentoEspecifico