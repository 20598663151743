import React, { useState } from 'react'
import './style.scss'

import { ContainerModal } from './../../components/Layout'
import { Button, Form, Input, Select, ValidacaoYup, SelectAsync } from './../../components/Form'

import { useLayout } from './../../context/Layout'
import api from './../../api'
import PageTracker from '../../components/Page/PageTracker';

function Relatorio() {

    const { setLoadFull } = useLayout()

    const [items, setItems] = useState([])

    async function onPesquisar(data) {
        setLoadFull(true)
        const resp = await api.post('relatorios/pesquisa', { mesAno: data.mes_ano })
        setItems(resp.data.value)
        setLoadFull(false)
    }

    function TopSearch() {

        return (
            <>
                <PageTracker pageUrl='/relatorios' />
                <div className="fundoPesquisa">
                    <Form
                        onSubmit={onPesquisar}
                        className='col-xl-12 col-lg-12'
                    >
                        <div className="itemPesquisa">
                            <SelectAsync name='mes_ano' col='col-lg-3' titulo='Selecione o mês/ano' router='relatorios/comboMesAno' />

                            <div col='col-lg-3'>
                                <Button name='FILTRAR' />
                            </div>

                        </div>
                    </Form>
                </div>

            </>
        )
    }

    return (
        <>
            <ContainerModal
                route='relatorios'
                title='Relatórios'
                showButtonExcluir={false}
                showButtonEditar={false}
                showButtonNovo={false}
                topSearch={TopSearch}
                dataExterno={items}
                campos={[
                    { nome: 'Empresa' },
                    { nome: 'Grupo' },
                    { nome: 'Valor' },
                    { nome: 'Data' }
                ]}
                validacao={{
                    nome: ValidacaoYup.string().required(),
                    status: ValidacaoYup.string().min(1).required()
                }}
                onNovoBefore={setData => {
                    setData({
                        status: { value: 'A', label: 'Ativo' }
                    })
                }}
            >

            </ContainerModal>
        </>
    )
}

export default Relatorio