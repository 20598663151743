import React, { useState, useEffect } from 'react';
import api from '../../api';
import { useAuth } from '../../context/Auth';
import './PhotoUploadModal.scss';

function PhotoUploadModal({ show, onHide }) {
    const [preview, setPreview] = useState(null);
    const [photo, setPhoto] = useState(null);
    const { updateUserPhoto } = useAuth();

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [show]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (preview) {
                URL.revokeObjectURL(preview);
            }
            setPreview(URL.createObjectURL(file));
            setPhoto(file);
        }
    };

    const handleSubmit = async () => {
        if (!photo) return;

        const formData = new FormData();
        formData.append('photo', photo);

        try {
            const response = await api.post('/users/upload-photo', formData);
            updateUserPhoto(response.data.url);
            onHide();
        } catch (error) {
            console.error('Error uploading photo:', error);
        }
    };

    if (!show) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="modal-header">
                    <h3>Alterar Foto de Perfil</h3>
                    <button className="close-button" onClick={onHide}>&times;</button>
                </div>
                <div className="modal-body">
                    <input
                        type="file"
                        className="form-control"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                    {preview && (
                        <div className="preview-container">
                            <img
                                src={preview}
                                alt="Preview"
                                className="preview-image"
                            />
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary" onClick={onHide}>Cancelar</button>
                    <button className="btn btn-primary" onClick={handleSubmit}>Salvar</button>
                </div>
            </div>
        </div>
    );
}

export default PhotoUploadModal;