// src/components/Page/PageTracker.js
import { useEffect } from 'react';
import { useAuth } from '../../context/Auth';
import api from '../../api';

function PageTracker({ pageUrl, publicationId, publicationTitle }) {
  const { getUser } = useAuth();
  const user = getUser();

  useEffect(() => {
    const startTime = Date.now();
    const currentUser = user; // Captura o usuário atual
    const currentPageUrl = pageUrl;
    const currentPublicationId = publicationId;
    const currentPublicationTitle = publicationTitle;

    return () => {
      const endTime = Date.now();
      const duration = (endTime - startTime) / 1000;

      api.post('/logs', {
        user: {
          name: currentUser.nome,
          email: currentUser.email,
          ip: currentUser.ip || '', // Certifique-se de que o campo IP esteja disponível
        },
        pages_visited: [
          {
            page_url: currentPageUrl,
            start_time: new Date(startTime).toISOString(),
            end_time: new Date(endTime).toISOString(),
            duration_seconds: duration,
            publication_id: currentPublicationId || null,
            publication_title: currentPublicationTitle || null,
          },
        ],
      })
    };
  }, []);

  return null;
}

export default PageTracker;