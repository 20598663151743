import React, { useState, useEffect, useRef } from 'react'

import './style.scss'

import { Container, Card, TopNavigation, mensagemSucesso, mensagemErro } from './../../../components/Layout'
import { Form, Input, Data, DataUTC, SelectAsync, Select, CNPJCPF, Telefone, ButtonSave, CEP } from './../../../components/Form'

// import ModalCropImage from './../../../components/Layout/ModalCropImage'

import { FiEdit } from 'react-icons/fi'

import { useLayout } from './../../../context/Layout'

import api from './../../../api'
import PageTracker from '../../../components/Page/PageTracker';

function DadosPessoais() {

    const { setLoadFull } = useLayout()
    const [load, setLoad] = useState(false)
    const [validacao, setValidacao] = useState('')

    const [avatar, setAvatar] = useState('')
    const [modalCrop, setModalCrop] = useState(false)
    const [changeAvatar, setChangeAvatar] = useState(false)

    const formRef = useRef(null)

    useEffect(() => {
        setLoadFull(true)
        onBuscaDados()
        setLoadFull(false)
    }, [])

    async function onBuscaDados() {
        const resp = await api.post('pessoais/edit', {})

        console.log('resp.data: ', resp.data)

        formRef.current.setData(resp.data.value[0])
    }

    async function onSave(data) {
        setLoadFull(true)

        const resp = await api.post('pessoais/update', data)

        if (resp.data.type !== 0) {
            setLoadFull(false)
            // setValidacao(resp.data.value)
            mensagemErro(resp.data.value)
            return
        }

        setLoadFull(false)
        mensagemSucesso('Dados atualizado com sucesso!')
    }

    function onSim(image) {

        setChangeAvatar(true)
        setAvatar(image)

    }

    return (
        <>
            <PageTracker pageUrl="/informacoes-pessoais/dados-pessoais" />
            <Container
                showTopNavigation={true}
                showSearch={false}
            >

                <Form
                    onSubmit={onSave}
                    setRef={formRef}
                    className='col-xl-12 col-lg-12 flex-pessoais'>

                    <Card
                        nome='Dados Pessoais'
                        col='col-xl-6 col-lg-6'
                    >

                        <Input name='Nom_Consultor' col='col-lg-12' titulo='Nome' placeholder='Digite seu nome' disabled />
                        <Select name='Sexo_Consultor' col='col-lg-12' titulo='Sexo' options={[
                            { value: 'M', label: 'Masculino' },
                            { value: 'F', label: 'Feminino' },
                        ]} />
                        <DataUTC name='DatNas_Consultor' col='col-lg-12' titulo='Data Nascimento' />
                        <SelectAsync name='Cod_Naturalidade' col='col-lg-12' titulo='Naturalidade' router='pessoais/comboMunicipio' />
                        <CNPJCPF name='CPF_Consultor' col='col-lg-12' titulo='CPF' cpf disabled />
                        <SelectAsync name='Cod_MunBase' col='col-lg-12' titulo='Base' router='pessoais/comboMunicipio' isDisabled />

                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <Select name='temfilhos_consultor' col='col-lg-6' titulo='Possuí Filhos' options={[
                                { value: 'S', label: 'Sim' },
                                { value: 'N', label: 'Não' },
                            ]} />
                            <Input name='Numfilhos_consultor' col='col-lg-6' titulo='Num. Filho' placeholder='Quantidade de Filhos' />
                        </div>

                        <Input name='Usuario_linkedin' col='col-lg-12' titulo='Usuário Linkedin' placeholder='Digite seu usuário' />
                        <SelectAsync name='Cod_EstCivil' col='col-lg-12' titulo='Estado Civil' router='pessoais/comboEstadoCivil' />
                        <SelectAsync name='Cod_Nacionalidade' col='col-lg-12' titulo='Nacionalidade' router='pessoais/comboPais' />
                        <Input name='RG_Consultor' col='col-lg-12' titulo='RG' placeholder='Digite seu RG' />
                        <Input name='PIS_Consultor' col='col-lg-12' titulo='PIS' placeholder='Digite seu PIS' />

                        <div className='col-lg-6 m-top-16'>
                            <ButtonSave name='Salvar' />
                        </div>

                    </Card>

                    <Card
                        nome='Contato'
                        col='col-xl-6 col-lg-6'
                    >

                        {/* <div className="row" className='divAvatar'>
                            <img src={avatar} />
                            <div className='editarAvatar' style={{ cursor: 'pointer' }} onClick={() => setModalCrop(true)}  >
                                <FiEdit size={16} color='#FFF' style={{ marginRight: 6 }} />
                                Editar
                            </div>
                        </div>

                        <div className="card-header border-0">
                            <h3 className="mb-0">Contato</h3>
                            <div style={{ display: 'flex', flexDirection: 'row' }}></div>
                        </div> */}

                        <Telefone name='Tel_Consultor' col='col-lg-12' titulo='Telefone' placeholder='(99) 9999-9999' />
                        <Telefone name='Cel_Consultor' col='col-lg-12' titulo='Celular' celular placeholder='(99) 9 9999-9999' />

                        <Telefone name='CelCorp_Consultor' col='col-lg-12' titulo='Celula Corporativo' celular placeholder='(99) 9 9999-9999' />
                        <Telefone name='Cel2_Consultor' col='col-lg-12' titulo='Celular Alternativo' celular placeholder='(99) 9 9999-9999' />

                        <Input name='Email_Consultor' col='col-lg-12' titulo='E-mail' placeholder='Digite seu e-mail' disabled />
                        <Input name='EmailSec_Consultor' col='col-lg-12' titulo='E-mail Secundário' placeholder='Digite seu e-mail secundário' />

                        <div className="card-header border-0">
                            <h3 className="mb-0">Endereço</h3>
                            <div style={{ display: 'flex', flexDirection: 'row' }}></div>
                        </div>

                        <CEP name='Cep_Consultor' col='col-lg-12' titulo='CEP' placeholder='99999-99' />
                        <SelectAsync name='Cod_Mun' col='col-lg-12' titulo='Cidade' router='pessoais/comboMunicipio' />
                        <Input name='Log_Consultor' col='col-lg-12' titulo='Rua' placeholder='Digite sua rua' />
                        <Input name='Bai_Consultor' col='col-lg-12' titulo='Bairro' placeholder='Digite seu bairro' />

                        <div className='col-lg-6 m-top-16' />

                    </Card>

                </Form>

            </Container>

            {/* <ModalCropImage
                showModal={modalCrop}
                closeModal={() => setModalCrop(false)}
                onSim={onSim}
                showSelect
            /> */}

        </>
    )
}

export default DadosPessoais