import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useField } from '@unform/core';

function TextRich({ name, titulo, col, groupClass, hidden, height = '200px', ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        if (defaultValue) {
            const blocksFromHtml = htmlToDraft(defaultValue);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [defaultValue]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: () => {
                return draftToHtml(convertToRaw(editorState.getCurrentContent()));
            },
            setValue: (ref, value) => {
                if (value) {
                    const blocksFromHtml = htmlToDraft(value);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    setEditorState(EditorState.createWithContent(contentState));
                }
            },
            clearValue: () => {
                setEditorState(EditorState.createEmpty());
            },
        });
    }, [fieldName, registerField, editorState]);

    const toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        },
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
        },
    };

    return (
        <div className={`${col}`} style={{ display: `${hidden ? 'none' : ''}` }}>
            <div className='cInputD'>
                <span>{titulo}</span>
            </div>
            <div className={`input-group input-group-merge input-group-alternative ${groupClass}`}>
                <div ref={inputRef} style={{ width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbar={toolbar}
                        editorClassName="editor-class"
                        wrapperClassName="wrapper-class"
                        toolbarClassName="toolbar-class"
                        {...rest}
                        style={{
                            color: '#000',
                        }}
                        editorStyle={{
                            minHeight: height,
                            padding: '0 15px',
                            color: '#000'
                        }}
                    />
                </div>
            </div>
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default TextRich;
