import React, { useState, useEffect } from 'react'
import './style.scss'

import { ContainerModal } from './../../../components/Layout'
import { Select, Input, SelectAsync, Textarea, Data, ValidacaoYup } from './../../../components/Form'

import api from './../../../api'
import PageTracker from '../../../components/Page/PageTracker';

function ExperienciaProfissional() {

    const [dataTerciario, setDataTerciario] = useState({})

    async function onBuscaDadosSelects(data) {

        if (data) {
            const terciario = await api.post('profissionais/comboSegmentoTerciario', data)
            setDataTerciario(terciario.data.value)

        }
    }

    return (
        <>
            <PageTracker pageUrl="/informacoes-pessoais/experiencia-profissional" />
            <ContainerModal
                route='profissionais'
                title='Experiência Profissional'
                showButtonPagination={false}
                validacao={{
                    emp_exprof: ValidacaoYup.string().min(3).required(),
                    Cod_SegMercTerc: ValidacaoYup.string().min(1).required(),
                }}
                showTopNavigation={true}
                campos={[
                    { nome: 'Empresa' },
                    { nome: 'Cargo' },
                ]}
            >

                <Input name='emp_exprof' col='col-lg-12' titulo='Empresa' placeholder='Digite o nome da empresa' />

                <SelectAsync
                    name='Cod_SegMercSec'
                    col='col-lg-12'
                    titulo='Segmento Secundário'
                    router='profissionais/comboSegmentoSecundario'
                    onChange={onBuscaDadosSelects}
                />

                <Select name='Cod_SegMercTerc' col='col-lg-12' titulo='Segmento Terciário' options={dataTerciario} />

                <SelectAsync name='Cod_PorteEmp' col='col-lg-12' titulo='Porte' router='profissionais/comboPorteEmpresa' />

                <Input name='cargo_exprof' col='col-lg-12' titulo='Cargo' placeholder='Digite o cargo' />

                <Textarea rows={4} name='atividade_exprof' col='col-lg-12' titulo='Principais atividades' placeho
                    lder='Descreva suas principais atividades' />
                {/* <Textarea rows={4} name='metodologias' col='col-lg-12' titulo='Metodologias' placeholder='Descreva metodologias utilizadas' /> */}

                <div style={{ display: 'flex', flexDirection: 'row' }} >
                    <Data name='dtadm_exprof' col='col-lg-6' titulo='Data da Admissão' />
                    <Data name='dtdem_exprof' col='col-lg-6' titulo='Data do Desligamento' />
                </div>

            </ContainerModal>
        </>
    )
}

export default ExperienciaProfissional