import Input from './Input'
import Data from './Data'
import DataUTC from './DataUTC'
import Textarea from './Textarea'
import Pass from './Pass'
import Select from './Select'
import SelectAsync from './SelectAsync'
import SelectCreate from './SelectCreate'
import TextRich from './TextRich'

import UF from './UF'
import Checkbox from './Checkbox'
import ValidacaoYup from './ValidacaoYup'
import Form from './Form'
import File from './File'
import Telefone from './Telefone'
import CEP from './CEP'
import CNPJCPF from './CNPJCPF'

import ButtonRemover from './Button/ButtonRemover'
import ButtonFechar from './Button/ButtonFechar'
import ButtonTab from './Button/ButtonTab'
import ButtonSair from './Button/ButtonSair'
import ButtonSave from './Button/ButtonSave'
import ButtonDelete from './Button/ButtonDelete'
import ButtonSaveNoSubmit from './Button/ButtonSaveNoSubmit'
import Button from './Button/Button'

export {    
    UF,
    Input,
    Data,
    DataUTC,
    Textarea,
    Pass,
    Select,
    SelectAsync,
    SelectCreate,
    
    Checkbox,
    ValidacaoYup,
    Form,
    File,
    Telefone,
    CEP,
    CNPJCPF,

    ButtonRemover,
    ButtonFechar,        
    ButtonTab,
    ButtonSair,
    ButtonSave,
    ButtonSaveNoSubmit,
    ButtonDelete,
    Button,
    TextRich,
}