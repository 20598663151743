import React, { useEffect } from 'react';
import './style.scss';

const AdministrativeContactModal = ({ contact, show, onHide }) => {
  useEffect(() => {
    if (!show) return;
    
    const handleEscape = (e) => {
      if (e.key === 'Escape') onHide();
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onHide, show]);

  if (!contact || !show) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) onHide();
  };

  return (
    <div className="administrative-modal-wrapper">
      <div className="modal-backdrop" onClick={handleBackdropClick}>
        <div className="modal-content">
          <button className="close-button" onClick={onHide}>&times;</button>
          <div className="contact-content">
            <div className="photo-container">
              {contact.photo_url ? (
                <img src={contact.photo_url} alt={contact.name} />
              ) : (
                <div className="placeholder-photo">
                  {contact.name?.charAt(0).toUpperCase()}
                </div>
              )}
            </div>
            <div className="contact-info">
              <h4>{contact.name}</h4>
              <p className="department">{contact.department}</p>
              <p><strong>Telefone:</strong> {contact.phone_number}</p>
              <p><strong>Email:</strong> {contact.email}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdministrativeContactModal;