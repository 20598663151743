import React, { useState, useEffect, useRef } from 'react'
import './style.scss'

import { Card, Container } from './../../components/Layout'
import { Button, Form, ValidacaoYup, SelectAsync } from './../../components/Form'

import { useLayout } from './../../context/Layout'
import api from './../../api'
import PageTracker from '../../components/Page/PageTracker';
import { SemRegistro, Table } from '../../components/Page'

function Projeto() {

    const { setLoadFull } = useLayout()
    const [items, setItems] = useState([])
    let columnRef = useRef([])

    async function onPesquisar(data) {

        const paramsSearch = {
            mes: data?.mes ? data.mes : '',
            ano: data?.ano ? data.ano : '',
        }

        setLoadFull(true)
        const resp = await api.post('projetos/minhaProgramacaoApp', paramsSearch)

        // console.log(resp);

        columnRef.current = resp.data.value

        let responseFormatted = [];

        // console.log(resp.data.value);

        if (resp.data.value) {
            resp.data.value.forEach((dado) => {
                responseFormatted.push({
                    header: {
                        empresa: dado[0],
                        proposta: dado[1],
                        documento: dado[2],
                        atuacao: dado[3],
                    },
                    results: dado[4],
                });
            });
        }

        // console.log(responseFormatted)

        setItems(responseFormatted)
        setLoadFull(false)
    }

    useEffect(() => {
        onPesquisar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function TopSearch() {

        return (
            <>
                <PageTracker pageUrl="/projetos" />
                <div className="fundoPesquisa">
                    <Form
                        onSubmit={onPesquisar}
                        className='col-xl-12 col-lg-12'
                        validacao={{
                            mes: ValidacaoYup.string().required(),
                            ano: ValidacaoYup.string().min(1).required()
                        }}
                    >
                        <div className="itemPesquisa">
                            <div className="filtrosMesAno">
                                <SelectAsync name='mes' col='col-lg-3' titulo='Selecione mês' router='projetos/comboMes' />
                                <SelectAsync name='ano' col='col-lg-3' titulo='Selecione ano' router='projetos/comboAno' />
                            </div>
                            <div col='col-lg-3'>
                                <Button name='FILTRAR' />
                            </div>
                        </div>
                    </Form>
                </div>

            </>
        )
    }

    const TableRow = ({ item }) => {

        const [expanded, setExpanded] = useState(false);

        const toggleExpander = () => {
            if (!expanded) {
                setExpanded(true);
            } else {
                setExpanded(false);
            }
        }

        return (
            <>
                <tr className="tableRow" style={{ cursor: 'pointer' }} key="main" onClick={toggleExpander}>
                    <td>{item.header.empresa.Empresa}</td>
                    <td>{item.header.proposta.Proposta}</td>
                    <td>{item.header.atuacao.Atuação}</td>
                    <td>{item.header.documento.PDF}</td>
                </tr>
                {expanded && (
                    <>
                        <tr className="headerExpanded">
                            <td>Mês</td>
                            <td>Previsto</td>
                            <td>Realizado</td>
                            <td>Saldo</td>
                        </tr>

                        {item.results.map(plot => (
                            <tr className="expandable" key="tr-expander">
                                <td>{plot.mes.charAt(0) + plot.mes.slice(1).toLowerCase()}</td>
                                <td>{plot.valores.previsto}</td>
                                <td>{plot.valores.realizado}</td>
                                <td>{plot.valores.saldo}</td>
                            </tr>
                        ))}
                    </>
                )}
            </>
        );
    }

    return (

        <Container
            showSearch={false}
            topSearch={TopSearch}
            showSearch={true}
        >

            <Card
                nome='Minha Programação'
                classAdd='homeCardClass'
            >

                {
                    items.length === 0 ? <SemRegistro /> :
                        <Table
                            campos={[
                                { nome: 'Empresa' },
                                { nome: 'Proposta' },
                                { nome: 'Atuação' },
                                { nome: 'Documento' },
                            ]}
                            trs={() => {

                                return items.map((item, index) => {
                                    return (
                                        <TableRow key={index} index={index + 1} item={item} />
                                    )
                                })
                            }}
                        />
                }

            </Card>
        </Container>
    )
}

export default Projeto