import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '../../components/Layout';
import { Section, Title, Line } from './../../components/Page/News/Components';
import PageTracker from '../../components/Page/PageTracker';
import api from '../../api';

const styles = {
    noticiaImagem: {
        float: 'right',
        marginLeft: '20px',
        marginBottom: '15px',
        position: 'relative',
        borderRadius: '5px',
        top: '15px',
        maxWidth: '30%',
    },
    noticiaContent: {
        width: '100%',
        display: 'block'
    },
    registrationLink: {
        marginBottom: '15px',
        fontSize: '16px'
    }
};

function NoticiaDetalhe() {
    const { id } = useParams();
    const [noticia, setNoticia] = useState(null);

    useEffect(() => {
        const fetchNoticia = async () => {
            try {
                const response = await api.get(`/home-contents/${id}`);
                setNoticia(response.data.value);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNoticia();
    }, [id]);

    if (!noticia) return <div>Carregando...</div>;

    return (
        <>
            <PageTracker pageUrl="detalhe-da-noticia" publicationId={noticia.id} publicationTitle={noticia.title} />
            <Container classAdd='newsClass' showSearch={false} loginBottom='45px'>
                <Section backgroundColor="#FFFFFF">
                    <Title color="#09324C">{noticia.title}</Title>
                    {noticia.home_type_id?.label === 'Eventos e Treinamentos' && noticia.link && (
                        <div style={{marginTop: '20px', fontSize: '16px'}}>
                            <a href={noticia.link} target="_blank" rel="noopener noreferrer">
                                Clique aqui para se inscrever
                            </a>
                        </div>
                    )}
                    {noticia.img_url && (
                        <img 
                            src={noticia.img_url} 
                            alt={noticia.title} 
                            style={styles.noticiaImagem}
                        />
                    )}
                    <Line />
                    <div style={styles.noticiaContent}>
                        <div dangerouslySetInnerHTML={{ __html: noticia.text }} />
                    </div>
                    <Line />
                </Section>
            </Container>
        </>
    );
}

export default NoticiaDetalhe;