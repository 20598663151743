import React, { useState, useEffect, useRef } from 'react'

import { ContainerModal } from './../../components/Layout'
import { ValidacaoYup, Input } from './../../components/Form'

function AdministrativeContact() {
    const [photoPreview, setPhotoPreview] = useState(null);
    const [photo, setPhoto] = useState(null);
    const formRef = useRef(null);

    const cleanupURLs = () => {
        if (photoPreview) {
            URL.revokeObjectURL(photoPreview);
        }
    };

    useEffect(() => {
        return () => {
            cleanupURLs();
        };
    }, []);

    const onEditarAfter = (data, setDataEdit, setLoadFull, setModal) => {
        setPhotoPreview(null);
        if (data.photo_url) {
            setPhotoPreview(data.photo_url);
        }

        setDataEdit(data);
        setLoadFull(false);
        setModal(true);
    };

    const onNovoBefore = (setDataEdit) => {
        setPhotoPreview(null);
        setDataEdit({});
    };

    const onSaveBefore = (data) => {
        if (photo) {
            data.photo = photo;
        }
        return data;
    };

    return (
        <>
            <ContainerModal
                route='administrative-contacts'
                title='Contatos Administrativos'
                showButtonPagination={false}
                useFormData={true}
                showSearch={false}
                validacao={{
                    department: ValidacaoYup.string().required(),
                    name: ValidacaoYup.string().required(),
                    phone_number: ValidacaoYup.string().required(),
                    email: ValidacaoYup.string().email().required(),
                }}
                campos={[
                    { nome: 'Departamento' },
                    { nome: 'Nome' },
                    { nome: 'Telefone' },
                    { nome: 'Email' }
                ]}
                onSaveBefore={onSaveBefore}
                onEditarAfter={onEditarAfter}
                onNovoBefore={onNovoBefore}
                formRef={formRef}
            >
                <Input 
                    name='department' 
                    col='col-lg-12'
                    titulo='Departamento'
                    placeholder='Digite o departamento'
                />
                <Input 
                    name='name' 
                    col='col-lg-12'
                    titulo='Nome'
                    placeholder='Digite o nome do contato'
                />
                <Input 
                    name='phone_number' 
                    col='col-lg-12'
                    titulo='Telefone'
                    placeholder='Digite o número de telefone'
                />
                <Input 
                    name='email' 
                    col='col-lg-12'
                    titulo='Email'
                    placeholder='Digite o email'
                />
                <div className="col-lg-12">
                    <Input 
                        name='photo' 
                        col='col-lg-12' 
                        titulo='Foto' 
                        type='file'
                        accept='image/*'
                        onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                if (photoPreview) {
                                    URL.revokeObjectURL(photoPreview);
                                }
                                const objectUrl = URL.createObjectURL(file);
                                setPhotoPreview(objectUrl);
                                setPhoto(file);
                            }
                        }}
                    />
                    {photoPreview && (
                        <div className="mt-2" style={{ 
                            display: 'flex', 
                            justifyContent: 'center' 
                        }}>
                            <img 
                                src={photoPreview} 
                                alt="Preview" 
                                style={{ 
                                    maxWidth: '200px', 
                                    maxHeight: '200px',
                                    objectFit: 'contain',
                                    borderRadius: '4px',
                                    border: '1px solid #ddd'
                                }} 
                            />
                        </div>
                    )}
                </div>
            </ContainerModal>
        </>
    )
}

export default AdministrativeContact
