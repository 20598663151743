import React, { createContext, useContext } from 'react'
import api from '../api'

const AuthContext = createContext()
const authKey = '@#aquila'

function AuthProvider({ children }) {

    function setUser(dados) {
        localStorage.setItem(authKey, JSON.stringify(dados))
    }

    function getUser() {
        const usuario = JSON.parse(localStorage.getItem(authKey))
        return usuario
    }

    function removeUser() {
        localStorage.removeItem(authKey)
    }

    function getNivel() {

        const usuario = JSON.parse(localStorage.getItem(authKey))        
        return usuario.token
    }

    function getToken() {
        const usuario = JSON.parse(localStorage.getItem(authKey))
        const token = usuario.token
        return Number(token)
    }

    function updateUserPhoto(photoUrl) {
        const userData = getUser();
        userData.avatar = photoUrl;
        setUser(userData);
    }

    async function fetchUserPhoto() {
        const user = getUser();
        if (!user?.id) return;

        try {
            const response = await api.post(`/users/photo`, { id: user.id });
            if (response.data.url) {
                updateUserPhoto(response.data.url);
            }
        } catch (error) {
            console.error('Error fetching user photo:', error);
        }
    }

    return (
        <AuthContext.Provider value={{ 
            setUser, 
            getUser, 
            removeUser, 
            getToken, 
            getNivel,
            updateUserPhoto,
            fetchUserPhoto // Add this new method to context
        }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = useContext(AuthContext)
    return context
}

export { useAuth, AuthProvider, authKey }