// src/pages/Agenda/index.js
import React from 'react';
import { Container } from '../../components/Layout';
import CalendarWithEditor from '../../components/CalendarWithEditor';
import PageTracker from '../../components/Page/PageTracker';
import './style.scss';

function Agenda() {
    return (
        <>
            <PageTracker pageUrl="/agenda" />
            <Container
                classAdd='semHomeClass'
                showSearch={false}
            >
                <div className="App">
                    <CalendarWithEditor />
                </div>
            </Container>
        </>
    );
}

export default Agenda;
