// src/components/Page/News/Title.js
import React from 'react';
import '@fontsource/montserrat';

const Link = ({ color, fontWeight, className, children }) => {
    const titleStyle = {
        color: color,
        fontSize: '14px',
        fontWeight: fontWeight,
        fontFamily: 'Montserrat, sans-serif'
    };
    
    return (
        <span style={titleStyle} className={className}>
            {children}
        </span>
    );
};

export default Link;
